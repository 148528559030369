import React, { useEffect, useState } from 'react';
import './Ubox.css';

const UBox = () => {
	const [shipments, setShipments] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [shipmentsLastModified, setShipmentsLastModified] = useState(null);
	const [refreshing, setRefreshing] = useState(false);

	useEffect(() => {
		const fetchShipments = async () => {
			try {
				const response = await fetch('https://api01.r2logistics.com/api/v1/ubox/shipments', {
					credentials: 'include',
					headers: {
						Accept: 'application/json',
					},
				});
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				const data = await response.json();

				const formattedData = data.map(order => ({
					shipmentIDs: order.shipment_ids || 'N/A',
					aljexIDs: order.aljex_ids || 'N/A',
					stopSequence: order.stop_sequence || [],
					originalMiles: order.original_miles || 0,
					optimizedMiles: order.optimized_miles || 0,
					optimizedPercentage: order.optimized_percentage || 0,
					revenue: order.revenue || 0,
				}));

				setShipmentsLastModified(response.headers.get('Last-Modified'));
				setShipments(formattedData);
				setLoading(false);
			} catch (err) {
				setError(err.message);
				setLoading(false);
			}
		};

		fetchShipments();
	}, []);

	const handleRefresh = async () => {
		setRefreshing(true);
		try {
			const response = await fetch('https://api01.r2logistics.com/api/v1/ubox/refresh', { 
				credentials: 'include',
			});
			if (response.ok) {
				const intervalId = setInterval(async () => {
					try {
						const updatedResponse = await fetch('https://api01.r2logistics.com/api/v1/ubox/shipments', {
							credentials: 'include',
							headers: {
								'If-Modified-Since': shipmentsLastModified,
							},
						});
						if (updatedResponse.status === 200) {
							const updatedData = await updatedResponse.json();
							const formattedData = updatedData.map(order => ({
								shipmentIDs: order.shipment_ids || 'N/A',
								aljexIDs: order.aljex_ids || 'N/A',
								stopSequence: order.stop_sequence || [],
								originalMiles: order.original_miles || 0,
								optimizedMiles: order.optimized_miles || 0,
								optimizedPercentage: order.optimized_percentage || 0,
								revenue: order.revenue || 0,
							}));
							setShipmentsLastModified(updatedResponse.headers.get('Last-Modified'));
							setShipments(formattedData);
							clearInterval(intervalId);
							setRefreshing(false);
						}
					} catch (err) {
						setRefreshing(false);
						console.error('Error fetching updated shipments:', err);
					}
				}, 3000);
			}
		} catch (err) {
			console.error('Error refreshing shipments:', err);
		}
	};

	if (loading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>Error: {error}</div>;
	}

	return (
		<div>
			<h1>UBox Combined Shipment Options</h1>
			<div style={{ display: 'flex', alignItems: 'center'}}>
				<p>Last Updated: {new Date(shipmentsLastModified).toLocaleString('en-US', { timeZoneName: 'short' })}</p>
				<button disabled={refreshing} style={{ 'marginLeft': '10px', padding: '5px 10px'}} onClick={handleRefresh}>Refresh Shipments</button>
			</div>
			<table>
				<thead>
					<tr>
						<th>Shipment IDs</th>
						<th>Aljex IDs</th>
						<th>Original Miles</th>
						<th>Optimized Miles</th>
						<th>Optimized %</th>
						<th>Stops</th>
					</tr>
				</thead>
				<tbody>
					{shipments.map((shipment, index) => (
						<tr key={index}>
							<td>{shipment.shipmentIDs}</td>
							<td>{shipment.aljexIDs}</td>
							<td>{shipment.originalMiles.toLocaleString()}</td>
							<td>{shipment.optimizedMiles.toLocaleString()}</td>
							<td>{shipment.optimizedPercentage.toLocaleString()}%</td>
							<td>
								<table>
									<thead>
										<tr>
											<th>Stop#</th>
											<th>City</th>
											<th>State</th>
											<th>Miles</th>
											<th>Capacity</th>
										</tr>
									</thead>
									<tbody>
										{shipment.stopSequence.map((stop, idx) => (
											<tr key={idx}>
												<td>{(idx + 1).toLocaleString()}</td>
												<td>{stop.location?.city || 'N/A'}</td>
												<td>{stop.location?.state || 'N/A'}</td>
												<td>{(stop.current_miles || 0).toLocaleString()}</td>
												<td>{(stop.current_capacity || 0).toLocaleString()}</td>
											</tr>
										))}
									</tbody>
								</table>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default UBox;